// data/technologiesData.js
import image1 from '../data/images/python.png';
import image2 from '../data/images/flutter.jfif';
import image3 from '../data/images/googlecolab.png';
import image4 from '../data/images/firebase.png';
import image5 from '../data/images/pythonanywhere.png';
import image6 from '../data/images/androidstudio.jfif';
import image7 from '../data/images/vscode.png';

// Programming Languages
export const pLanguages = [
  { name: 'Python', image: image1 }
];

// Frameworks
export const frameworks = [
  { name: 'Flutter', image: image2 }
];

// IDE
export const ide = [
  { name: 'VS Code', image: image7 },
  { name: 'Android Studio', image: image6 },
  { name: 'Google Colabaratory', image: image3 }
];

// Databases
export const databases = [
  { name: 'Firebase', image: image4 }
];

// Other Technologies
export const otherstechnologies = [
  { name: 'PythonAnyWhere', image: image5 }
];