import './App.css';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Technologies from './components/Technologies/Technologies';
import Footer from './components/Footer/Footer';
import Contact from './components/Contact/Contact';
import Domain from './components/Domain/Domain';
import Milestone from './components/Milestone/Milestone';
import Document from './components/Document/Document';
import Achievements from './components/Achivement/Achivment';
import AboutUs from './components/Aboutus/Aboutus';

function App() {
  return (
    <div className="App">
      <Header/>
      <Hero/>
      <Domain/>
      <Milestone/>
      <Document/>
      <Technologies/>
      <Achievements/>
      <AboutUs/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;