/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import './Hero.css';
import image1 from '../../assests/heroimage.jpg';
import Button from '@mui/material/Button';
import { Link } from 'react-scroll';

const Hero = () => {
  return (
    <div className="hero-container" id="home">
      <div className="image-container">
        <img
          src={image1}
          alt="Hero Image"
          className="image1"
        />
        <div className="overlay"></div>
        <div className="content">
          <h1 className="hero-heading">
            Welcome to GreenAg
          </h1>
          <p className="hero-subheading">
            Explore sustainable agriculture solutions.
          </p>
          <Link to="contactus" id='contactus'>
            <Button
              className="btn-hireme"
              variant="contained"
              color="primary"
            >
              Contact Us
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;