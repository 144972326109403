import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import React, { useRef } from 'react';
import './Contact.css';
import emailjs from '@emailjs/browser';
import Button from '@mui/material/Button';

export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
  
    emailjs
      .sendForm('service_g76obnq', 'template_dsiqdpb', form.current, 'VBCaLk65qRAfa13BV')
      .then(
        (result) => {
          console.log(result.text);
          alert('Message sent successfully!');
          window.location.reload(); // Reload the page
        },
        (error) => {
          console.log(error.text);
          alert('Failed to send email. Please try again.');
        }
      );
  };  

  return (
    <div className="contact-container" id="contactus">
      <div className="contact-heading">My Contacts</div>
      <div className="contact-form">
        <form ref={form} className="contact-message" onSubmit={sendEmail}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              '& .MuiTextField-root': { marginBottom: '1rem', width: '400px' },
              '& .contact-textarea': { marginBottom: '1rem', width: '400px', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '5px' },
              '& .contact-btn': { background: 'goldenrod', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer', transition: 'background-color 0.3s ease' },
              '& .contact-btn:hover': { backgroundColor: 'darkgoldenrod' },
            }}
          >
            <TextField className="contact-textfield" type="text" name="from_name" label={'Name'} id="margin-none" required />
            <TextField className="contact-textfield" type="email" name="user_email" label={'Email'} id="margin-dense" margin="dense" required />
            <TextareaAutosize
              className="contact-textarea" 
              name="message" 
              placeholder="Message" 
              required 
            />
            <Button className="contact-btn" variant="contained" type="submit">
              Send
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
}