import React from 'react';
import './Domain.css';

const Domain = () => {
    return (
        <div className="domain-container" id="domain">
            <div className="domain-content">
                {/* <div className="column card1">
                    <h2>What is GreenAg</h2>
                    <p>
                        This section provides an overview of the specific area or field of agriculture that GreenAg focuses on.
                    </p>
                </div> */}
                <div className="column card2">
                    <h2>Background</h2>
                    <p>
                        This section may include historical context, significant events, or trends in the field of agriculture that are relevant to GreenAg's mission and objectives.
                    </p>
                </div>
                <div className="column card2">
                    <h2>Research Gap</h2>
                    <p>
                        This section highlights the specific challenges or areas of need within GreenAg's domain that the project seeks to fill.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Domain;