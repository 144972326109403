import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className='footer-container'>
      <hr/>
    <div className='footer-content'>
      AssignmentSaga @ 2023
      </div>
      </div>
  )
}

export default Footer