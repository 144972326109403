import React, { useEffect } from 'react';
import './Milestone.css';

const Milestone = () => {
    const milestones = [
            {
                title: "Brainstorming workshop",
                date: "16-Dec-2022"
            },
            {
                title: "Group registration",
                date: "20-Jan-2023"
            },
            {
                title: "Topic evaluation",
                date: "30-Jan-2023"
            },
            {
                title: "Project charter",
                date: "24-Mar-2023"
            },
            {
                title: "Proposal reports (draft)",
                date: "27-31 Mar-2023"
            },
            {
                title: "Proposal presentation",
                date: "5-May-2023"
            },
            {
                title: "Proposal reports (final - for marking)",
                date: "22-26 May-2023"
            },
            {
                title: "Progress Presentation – I",
                date: "22-May-2023"
            },
            {
                title: "Status document I",
                date: "20-Jun-2023"
            },
            {
                title: "Research paper",
                date: "10-Sep-2023"
            },
            {
                title: "Final reports",
                date: "4-6 Sep-2023"
            },
            {
                title: "Progress Presentation – II",
                date: "4-6 Sep-2023"
            },
            {
                title: "Status document II",
                date: "6-Nov-2023"
            },
            {
                title: "Research paper registration notification",
                date: "30-Oct to 03-Nov"
            },
            {
                title: "Final presentation & VIVA",
                date: "6-Nov-2023"
            },
            {
                title: "Project website",
                date: "30-Oct-2023"
            },
            {
                title: "Research logbook",
                date: "27-2023"
            },
            {
                title: "Final Report (proofread)",
                date: "TBD"
            }
    ];

    useEffect(() => {
        const milestoneCards = document.querySelectorAll('.milestone-card');

        const handleScroll = () => {
            milestoneCards.forEach((card, index) => {
                const cardPosition = card.getBoundingClientRect().top;
                const screenPosition = window.innerHeight / 1.3;

                if (cardPosition < screenPosition) {
                    card.classList.add('active');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="milestone-container" id="milestones">
            <div className="milestone-content">
                <h2>Milestones</h2>
                <div className="milestone-card-container">
                    {milestones.map((milestone, index) => (
                        <div key={index} className="milestone-card">
                            <div className="milestone-side milestone-side-1">
                                <div className="milestone-marker"></div>
                                {index !== 0 && <div className="arrow-up"></div>}
                            </div>
                            <div className="milestone-side milestone-side-2">
                                <span className="milestone-title">{milestone.title}</span>
                            </div>
                            <div className="milestone-side milestone-side-3">
                                <span className="milestone-date">{milestone.date}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Milestone;