import React from 'react';
import './Achivment.css'; // Import your CSS file for styling

const Achievements = () => {
  const achievementsData = [
    {
      title: "Achievement 1",
      description: "Description for Achievement 1."
    },
    {
      title: "Achievement 2",
      description: "Description for Achievement 2."
    },
    // Add more achievements as needed
  ];

  return (
    <div className='achievements-container' id='achivments'>
      <h1 className='achievements-heading'>
        My Achievements
      </h1>
      <div className='achievements-list'>
        {achievementsData.map((achievement, index) => (
          <div key={index} className='achievement'>
            <h2>{achievement.title}</h2>
            <p>{achievement.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Achievements;