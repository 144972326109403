import React from 'react';
import applogo from '../../assests/applogo.jpg';
import pdfIcon from '../../assests/applogo.jpg'; // Import the PDF icon
import './Document.css';

const Document = () => {
  const filesToDownload1 = [
    {
      fileName: 'Research Components.pdf',
      filePath: '/Research%20Components.pdf',
      imageSrc: applogo
    },
    {
      fileName: 'Another Document.pdf',
      filePath: '/Another%20Document.pdf',
      imageSrc: applogo
    },
    {
        fileName: 'Another Document.pdf',
        filePath: '/Another%20Document.pdf',
        imageSrc: applogo
      },
      {
        fileName: 'Another Document.pdf',
        filePath: '/Another%20Document.pdf',
        imageSrc: applogo
      }
  ];

  const filesToDownload2 = [
    {
      fileName: 'Research Components.pdf',
      filePath: '/Research%20Components.pdf',
      imageSrc: applogo
    },
    {
      fileName: 'Another Document.pdf',
      filePath: '/Another%20Document.pdf',
      imageSrc: applogo
    },
    {
        fileName: 'Another Document.pdf',
        filePath: '/Another%20Document.pdf',
        imageSrc: applogo
      },
      {
        fileName: 'Another Document.pdf',
        filePath: '/Another%20Document.pdf',
        imageSrc: applogo
      }
  ];

  const downloadFile = (filePath, fileName) => {
    const link = document.createElement('a');
    link.href = process.env.PUBLIC_URL + filePath;
    link.target = '_blank';
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div id='documents' className="center-content">
      <h2 className="header">Download PDF Documents</h2>
      <div className="file-row">
        {filesToDownload1.map((file, index) => (
          <div key={index} className="file-container">
            <span className="download-link" onClick={() => downloadFile(file.filePath, file.fileName)}>
              <img src={pdfIcon} alt={file.fileName} className="pdf-icon" />
            </span>
          </div>
        ))}
      </div>

      <h2 className="header">Download PDF Documents</h2>
      <div className="file-row">
        {filesToDownload2.map((file, index) => (
          <div key={index} className="file-container">
            <span className="download-link" onClick={() => downloadFile(file.filePath, file.fileName)}>
              <img src={pdfIcon} alt={file.fileName} className="pdf-icon" />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Document;