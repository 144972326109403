import React from 'react';
import './Technologies.css';
import { pLanguages, frameworks, databases, ide, otherstechnologies } from '../../data/technologiesData';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const Technologies = () => {
  return (
    <div className='skills-container' id='technologies'>
      <h1 className='skills-heading'>
        Tools & Technologies
      </h1>
      
      {/* Programming Languages */}
      <div className='skills-section'>
        <h2 className='skills-heading2'>
          Programming Languages
        </h2>
        <div className='skill-content'>
          {pLanguages.map((technologies, i) => (
            <Card sx={{ maxWidth: 345 }} className="skill-card" key={i}>
              <CardContent className='skill-card-content'>
                <img src={technologies.image} style={{ width: "100px" }} alt={technologies.name} />
                <Typography gutterBottom variant="h5" component="div">
                  {/* {technologies.name} */}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
      
      {/* Frameworks */}
      <div className='skills-section'>
        <h2 className='skills-heading2'>
          Frameworks
        </h2>
        <div className='skill-content'>
          {frameworks.map((technologies, i) => (
            <Card sx={{ maxWidth: 345 }} className="skill-card" key={i}>
              <CardContent className='skill-card-content'>
                <img src={technologies.image} style={{ width: "100px" }} alt={technologies.name} />
                <Typography gutterBottom variant="h5" component="div">
                  {/* {technologies.name} */}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
      
      {/* IDE */}
      <div className='skills-section'>
        <h2 className='skills-heading2'>
          IDE
        </h2>
        <div className='skill-content'>
          {ide.map((technologies, i) => (
            <Card sx={{ maxWidth: 345 }} className="skill-card" key={i}>
              <CardContent className='skill-card-content'>
                <img src={technologies.image} style={{ width: "100px" }} alt={technologies.name} />
                <Typography gutterBottom variant="h5" component="div">
                  {/* {technologies.name} */}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
      
      {/* Databases */}
      <div className='skills-section'>
        <h2 className='skills-heading2'>
          Database
        </h2>
        <div className='skill-content'>
          {databases.map((technologies, i) => (
            <Card sx={{ maxWidth: 345 }} className="skill-card" key={i}>
              <CardContent className='skill-card-content'>
                <img src={technologies.image} style={{ width: "100px" }} alt={technologies.name} />
                <Typography gutterBottom variant="h5" component="div">
                  {/* {technologies.name} */}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
      
      {/* Other Technologies */}
      <div className='skills-section'>
        <h2 className='skills-heading2'>
          Server
        </h2>
        <div className='skill-content'>
          {otherstechnologies.map((technologies, i) => (
            <Card sx={{ maxWidth: 345 }} className="skill-card" key={i}>
              <CardContent className='skill-card-content'>
                <img src={technologies.image} style={{ width: "100%" }} alt={technologies.name} />
                <Typography gutterBottom variant="h5" component="div">
                  {/* {technologies.name} */}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Technologies;