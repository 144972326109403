import React from 'react';
import './Aboutus.css';
import image1 from '../Aboutus/images/nipun.png';
import image2 from '../Aboutus/images/pasindu.png';
import image3 from '../Aboutus/images/prabash.png';
import image4 from '../Aboutus/images/kavindu.png';
import image5 from '../Aboutus/images/nipun.png';
import image6 from '../Aboutus/images/nipun.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const AboutUs = () => {
  const teamMembers = [
    {
      name: "Nipun Sandeepa",
      position: "Student",
      bio: "Sri Lanka Institute of Information Technology - Malabe, Sri Lanka",
      imageSrc: image1,
      facebook: "https://www.facebook.com/nipun.sandeepa/",
      linkedin: "https://www.linkedin.com/in/nipunsandeepa/"
    },
    {
      name: "Pasindu Perera",
      position: "Student",
      bio: "Sri Lanka Institute of Information Technology - Malabe, Sri Lanka",
      imageSrc: image2,
      facebook: "https://www.facebook.com/nipun.sandeepa/",
      linkedin: "https://www.linkedin.com/in/nipunsandeepa/"
    },
    {
      name: "Buddhima Prabashwara",
      position: "Student",
      bio: "Sri Lanka Institute of Information Technology - Malabe, Sri Lanka",
      imageSrc: image3,
      facebook: "https://www.facebook.com/nipun.sandeepa/",
      linkedin: "https://www.linkedin.com/in/nipunsandeepa/"
    },
    {
      name: "Kavindu Shehan",
      position: "Student",
      bio: "Sri Lanka Institute of Information Technology - Malabe, Sri Lanka",
      imageSrc: image4,
      facebook: "https://www.facebook.com/nipun.sandeepa/",
      linkedin: "https://www.linkedin.com/in/nipunsandeepa/"
    },
    {
      name: "Sanath Jayawardhana",
      position: "Supervisor",
      bio: "Sri Lanka Institute of Information Technology - Malabe, Sri Lanka",
      imageSrc: image5,
      facebook: "https://www.facebook.com/nipun.sandeepa/",
      linkedin: "https://www.linkedin.com/in/nipunsandeepa/"
    },
    {
      name: "Uditha Dharmakirthi",
      position: "CoSupervisor",
      bio: "Sri Lanka Institute of Information Technology - Malabe, Sri Lanka",
      imageSrc: image6,
      facebook: "https://www.facebook.com/nipun.sandeepa/",
      linkedin: "https://www.linkedin.com/in/nipunsandeepa/"
    }
  ];

  const students = teamMembers.filter(member => member.position === "Student");
  const supervisors = teamMembers.filter(member => member.position !== "Student");

  return (
    <div className='about-us-container' id='aboutus'>
      <h1 className='about-us-heading'>
        About Us
      </h1>
      <div className='team-members'>
      <div className='supervisors'>
          {supervisors.map((member, index) => (
            <div key={index} className='member'>
              <h1>{member.position}</h1>
              <img src={member.imageSrc} alt={member.name} className='member-image' />
              <h2>{member.name}</h2>
              <p>{member.bio}</p>
              <div className="social-links">
  <a href={member.facebook} target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faFacebook} className="icon" />
  </a>
  <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faLinkedin} className="icon" />
  </a>
</div>
            </div>
          ))}
        </div>
        <div className='students'>
          {students.map((member, index) => (
            <div key={index} className='member'>
              <h1>{member.position}</h1>
              <img src={member.imageSrc} alt={member.name} className='member-image' />
              <h2>{member.name}</h2>
              <p>{member.bio}</p>
              <div className="social-links">
  <a href={member.facebook} target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faFacebook} className="icon" />
  </a>
  <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faLinkedin} className="icon" />
  </a>
</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AboutUs;